export const smartsuppScript = `var _smartsupp = _smartsupp || {};
_smartsupp.key = 'e13faee7be28cd07a0b2e3aec182ebe91be81073';
window.smartsupp||(function(d) {
  var s,c,o=smartsupp=function(){ o._.push(arguments)};o._=[];
  s=d.getElementsByTagName('script')[0];c=d.createElement('script');
  c.type='text/javascript';c.charset='utf-8';c.async=true;
  c.src='https://www.smartsuppchat.com/loader.js?';s.parentNode.insertBefore(c,s);
})(document);`

export const clarityScript = `(function(c,l,a,r,i,t,y){
  c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
  t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
  y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
})(window, document, "clarity", "script", "kr4yzom6jb");`

export const googleTagManagerScript = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-W8RRDZG');`

export const googleTagManagerNoscript = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-W8RRDZG" height="0" width="0" style="display:none;visibility:hidden"></iframe>`

export const gtagScript = `window.dataLayer = window.dataLayer || [];
  window.gtag = function gtag(){dataLayer.push(arguments);};
  gtag('js', new Date());

  gtag('config', 'G-HG7F9XXDBT');`

export const gtagSrc = `https://www.googletagmanager.com/gtag/js?id=G-HG7F9XXDBT`

export const facebookPixelScript = `!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window,document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '646333834311396'); 
fbq('track', 'PageView');`

export const facebookPixelNoscript = ` <img height="1" width="1" 
src="https://www.facebook.com/tr?id=646333834311396&ev=PageView
&noscript=1"/>`
