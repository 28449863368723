import { Link, useLocation } from '@remix-run/react'
import { useRef, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { Button } from '#app/components/ui/button'
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuGroup,
	DropdownMenuItem,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
} from '#app/components/ui/dropdown-menu'

export function ServicesMenu() {
	const [open, setOpen] = useState(false)
	const location = useLocation()
	const isHovering = useRef(false)
	const highlight =
		location.pathname.startsWith('/b2c') ||
		location.pathname.startsWith('/b2b') ||
		open

	return (
		<DropdownMenu open={open} onOpenChange={setOpen}>
			<DropdownMenuTrigger asChild>
				<Button
					variant="ghost"
					onMouseEnter={() => {
						isHovering.current = true
						setTimeout(() => {
							if (isHovering.current) setOpen(true)
						}, 350)
					}}
					onMouseLeave={() => {
						isHovering.current = false
					}}
					className={twMerge(
						'text-white transition-all',
						highlight && 'bg-accent text-accent-foreground',
					)}
				>
					Služby
					<svg
						width="20"
						height="20"
						viewBox="0 0 20 20"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M13.75 8.75L10 12.5L6.25 8.75"
							stroke={highlight ? '#054EB1' : 'white'}
							strokeWidth="1.13"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				</Button>
			</DropdownMenuTrigger>
			<DropdownMenuContent className="w-80">
				<DropdownMenuGroup>
					<DropdownMenuItem asChild>
						<Link to="/b2c" prefetch="intent">
							<div className="flex gap-4">
								<div className="grid place-items-center">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="32"
										height="32"
										fill="none"
									>
										<rect
											width="31"
											height="31"
											x=".5"
											y=".5"
											fill="#F7FAFF"
											rx="15.5"
										/>
										<path
											stroke="#054EB1"
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth="1.5"
											d="M11.167 23.5c-.934 0-1.4 0-1.757-.181a1.667 1.667 0 0 1-.728-.729c-.182-.356-.182-.823-.182-1.757v-6.029c0-.478 0-.718.062-.938.054-.195.144-.379.265-.542.136-.184.325-.33.702-.625l5.652-4.396c.293-.227.44-.341.601-.385a.834.834 0 0 1 .436 0c.161.044.308.158.6.385L22.47 12.7c.378.294.567.441.703.625.12.163.21.347.265.542.062.22.062.46.062.938v6.03c0 .933 0 1.4-.182 1.756-.16.314-.414.569-.728.729-.357.181-.823.181-1.757.181"
										/>
										<path
											stroke="#F7658B"
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth="1.5"
											d="M18.5 23.5v-6.167c0-.466 0-.7-.09-.878a.833.833 0 0 0-.365-.364C17.867 16 17.633 16 17.167 16h-2.334c-.466 0-.7 0-.878.09a.833.833 0 0 0-.364.365c-.091.178-.091.412-.091.878V23.5h4.917"
										/>
										<rect
											width="31"
											height="31"
											x=".5"
											y=".5"
											stroke="#EAF0FF"
											rx="15.5"
										/>
									</svg>
								</div>
								<div className="flex flex-col">
									<span className="text-sm font-semibold leading-normal text-slate-700">
										Domácnosti
									</span>
									<span className="text-xs leading-tight text-slate-500">
										Čistý domov snadno a rychle
									</span>
								</div>
							</div>
						</Link>
					</DropdownMenuItem>
					<DropdownMenuSeparator />
					<DropdownMenuItem asChild>
						<Link to="/b2b" prefetch="intent">
							<div className="flex gap-4">
								<div className="grid place-items-center">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="32"
										height="32"
										fill="none"
									>
										<rect
											width="31"
											height="31"
											x=".5"
											y=".5"
											fill="#F7FAFF"
											rx="15.5"
										/>
										<path
											stroke="#054EB1"
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth="1.5"
											d="M12.25 15.167H9.832c-.467 0-.7 0-.879.09a.833.833 0 0 0-.364.365c-.09.178-.09.411-.09.878v7m11.25-8.333h2.416c.467 0 .7 0 .878.09.157.08.285.208.364.365.091.178.091.411.091.878v7m-3.75 0V11.167c0-.934 0-1.4-.181-1.757a1.667 1.667 0 0 0-.729-.728c-.356-.182-.823-.182-1.756-.182h-2.167c-.933 0-1.4 0-1.757.182-.313.16-.568.414-.728.728-.182.357-.182.823-.182 1.757V23.5m12.084 0H7.666"
										/>
										<path
											stroke="#F7658B"
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth="1.5"
											d="M15.168 11.834h1.667m-1.667 3.333h1.667M15.168 18.5h1.667"
										/>
										<rect
											width="31"
											height="31"
											x=".5"
											y=".5"
											stroke="#EAF0FF"
											rx="15.5"
										/>
									</svg>
								</div>
								<div className="flex flex-col">
									<span className="text-sm font-semibold leading-normal text-slate-700">
										Firmy
									</span>
									<span className="text-xs leading-tight text-slate-500">
										Profesionální úklidy firem
									</span>
								</div>
							</div>
						</Link>
					</DropdownMenuItem>
				</DropdownMenuGroup>
			</DropdownMenuContent>
		</DropdownMenu>
	)
}
