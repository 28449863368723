import { useLocation } from '@remix-run/react'
import posthog from 'posthog-js'
import { useEffect } from 'react'

export const usePosthog = () => {
	const location = useLocation()

	useEffect(() => {
		posthog.init(ENV.POSTHOG_API_KEY, {
			api_host: 'https://eu.posthog.com',
			capture_pageview: false,
		})
	}, [])

	useEffect(() => void posthog.capture('$pageview'), [location])
}
