import { cssBundleHref } from '@remix-run/css-bundle';
import {
  json,
  type HeadersFunction,
  type LinksFunction,
  type MetaFunction,
  type LoaderFunctionArgs } from
'@remix-run/node';
import {
  Link,
  Links,
  LiveReload,
  Meta,
  NavLink,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useLocation } from
'@remix-run/react';
import { withSentry } from '@sentry/remix';
import { type ReactNode, useMemo } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { AuthenticityTokenProvider } from 'remix-utils/csrf/react';
import { HoneypotProvider } from 'remix-utils/honeypot/react';
import { CookiesConsent } from './components/cookies-consent.tsx';
import { GeneralErrorBoundary } from './components/error-boundary.tsx';
import { MobileMenu } from './components/mobile-menu.tsx';
import { EpicProgress } from './components/progress-bar.tsx';
import { useToast } from './components/toaster.tsx';
import { Button } from './components/ui/button.tsx';
import {
  DropdownMenu,
  DropdownMenuTrigger } from
'./components/ui/dropdown-menu.tsx';
import { href as iconsHref } from './components/ui/icon.tsx';
import { EpicToaster } from './components/ui/sonner.tsx';
import { usePosthog } from './hooks/use-posthog.ts';
import { DownloadApp } from './routes/_marketing+/index/download-app.tsx';
import { ServicesMenu } from './routes/_marketing+/index/services-menu.tsx';
import { UserMenu } from './routes/_marketing+/index/user-menu.tsx';
import recaptchaStyleSheetUrl from './styles/recaptcha.css';
import sonnerStyleSheetUrl from './styles/sonner.css';
import tailwindStyleSheetUrl from './styles/tailwind.css';
import { api } from './utils/api/index.ts';
import { getUserAccessToken } from './utils/auth.server.ts';
import { ClientHintCheck, getHints } from './utils/client-hints.tsx';
import { csrf } from './utils/csrf.server.ts';
import { getEnv } from './utils/env.server.ts';
import {
  clarityScript,
  smartsuppScript,
  googleTagManagerScript,
  googleTagManagerNoscript,
  gtagScript,
  gtagSrc,
  facebookPixelScript,
  facebookPixelNoscript } from
'./utils/external-scripts.ts';
import { honeypot } from './utils/honeypot.server.ts';
import { cn, combineHeaders, getDomainUrl } from './utils/misc.tsx';
import { useNonce } from './utils/nonce-provider.ts';
import { makeTimings, time } from './utils/timing.server.ts';
import { getToast } from './utils/toast.server.ts';

export const links: LinksFunction = () => {
  return [
  // Preload svg sprite as a resource to avoid render blocking
  { rel: 'preload', href: iconsHref, as: 'image' },
  // Preload CSS as a resource to avoid render blocking
  { rel: 'preload', href: tailwindStyleSheetUrl, as: 'style' },
  cssBundleHref ? { rel: 'preload', href: cssBundleHref, as: 'style' } : null,
  { rel: 'mask-icon', href: '/favicons/mask-icon.svg' },
  {
    rel: 'alternate icon',
    type: 'image/png',
    href: '/favicons/favicon-32x32.png'
  },
  { rel: 'apple-touch-icon', href: '/favicons/apple-touch-icon.png' }, (
  {
    rel: 'manifest',
    href: '/site.webmanifest',
    crossOrigin: 'use-credentials'
  } as const), // necessary to make typescript happy
  //These should match the css preloads above to avoid css as render blocking resource
  { rel: 'icon', href: '/favicons/favicon.ico', type: 'image/x-icon' },
  { rel: 'stylesheet', href: tailwindStyleSheetUrl },
  cssBundleHref ? { rel: 'stylesheet', href: cssBundleHref } : null,
  { rel: 'preconnect', href: 'https://fonts.googleapis.com' },
  { rel: 'preconnect', href: 'https://fonts.gstatic.com' },
  {
    rel: 'stylesheet',
    href: 'https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap'
  },
  { rel: 'stylesheet', href: sonnerStyleSheetUrl },
  { rel: 'stylesheet', href: recaptchaStyleSheetUrl }].
  filter(Boolean);
};

export const meta: MetaFunction<typeof loader> = ({ data }) => {
  return [
  { title: data ? 'IZZY' : 'Error | IZZY' },
  { name: 'description', content: `Profesionální úklid s Izzy ještě dnes` },
  {
    name: 'keywords',
    content:
    'uklid, úklid domácnosti, uklid praha, úklidová firma, prace uklid, paní na úklid, uklid domu, uklidove prace, praní prádla, mytí oken praha, paní na úklid praha, úklidové firmy, úklid praha'
  }];

};

export async function loader({ request }: LoaderFunctionArgs) {
  const timings = makeTimings('root loader');
  const accessToken = await time(() => getUserAccessToken(request), {
    timings,
    type: 'getUserAccessToken',
    desc: 'getUserAccessToken in root'
  });

  const { toast, headers: toastHeaders } = await getToast(request);
  const honeyProps = honeypot.getInputProps();
  const [csrfToken, csrfCookieHeader] = await csrf.commitToken();

  let profile = null;
  if (accessToken) {
    const { data } = await api.getProfile(request);
    profile = data;
  }

  return json(
    {
      accessToken,
      requestInfo: {
        hints: getHints(request),
        origin: getDomainUrl(request),
        path: new URL(request.url).pathname,
        userPrefs: { theme: 'light' }
      },
      ENV: getEnv(),
      toast,
      honeyProps,
      csrfToken,
      profile
    },
    {
      headers: combineHeaders(
        { 'Server-Timing': timings.toString() },
        toastHeaders,
        csrfCookieHeader ? { 'set-cookie': csrfCookieHeader } : null
      )
    }
  );
}

export const headers: HeadersFunction = ({ loaderHeaders }) => {
  const headers = {
    'Server-Timing': loaderHeaders.get('Server-Timing') ?? ''
  };
  return headers;
};

const Document = ({
  children,
  nonce,
  env = {}




}: {children: ReactNode;nonce: string;env?: Record<string, string>;}) => {
  return (
    <html lang="cs" className="light h-full overflow-x-hidden">
			<head>
				<ClientHintCheck nonce={nonce} />
				<Meta />
				<meta charSet="utf-8" />
				<meta
          name="viewport"
          content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1" />

				{env.MODE === 'production' &&
        <>
						<script dangerouslySetInnerHTML={{ __html: facebookPixelScript }} />
						<noscript
            dangerouslySetInnerHTML={{ __html: facebookPixelNoscript }} />

					</>}

				<Links />
			</head>
			<body className="bg-background text-foreground">
				{children}
				<script
          nonce={nonce}
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(env)}`
          }} />


				<script dangerouslySetInnerHTML={{ __html: smartsuppScript }} />

				{env.MODE === 'production' &&
        <>
						<script dangerouslySetInnerHTML={{ __html: clarityScript }} />
						<script
            dangerouslySetInnerHTML={{ __html: googleTagManagerScript }} />

						<noscript
            dangerouslySetInnerHTML={{ __html: googleTagManagerNoscript }} />

						<script dangerouslySetInnerHTML={{ __html: gtagScript }} />
						<script async src={gtagSrc} />
					</>}


				<ScrollRestoration nonce={nonce} />
				<Scripts nonce={nonce} />
				<LiveReload nonce={nonce} />
			</body>
		</html>);

};

const desktopItems = [
{ label: 'Jak to funguje', to: '/how-it-works' },
{ to: '/services' },
{ label: 'Paní na úklid', to: '/cleaning-providers' },
{ label: 'Ceník', to: '/pricing' },
{ label: 'O nás', to: '/about' },
{ label: 'Kontakt', to: '/contact' }];


function App() {
  const data = useLoaderData<typeof loader>();
  const nonce = useNonce();
  useToast(data.toast);
  const location = useLocation();
  const hideHeader =
  location.pathname === '/login' ||
  location.pathname === '/forgot-password' ||
  location.pathname === '/change-password' ||
  location.pathname === '/signup' ||
  location.pathname.startsWith('/dashboard');

  usePosthog();

  return (
    <Document nonce={nonce} env={data.ENV}>
			{!hideHeader && <AppHeader />}

			<div className="flex min-h-screen flex-col justify-between">
				<div className="flex-1">
					<Outlet />
				</div>
			</div>
			<EpicToaster closeButton position="top-center" />
			<EpicProgress />
			<CookiesConsent />
		</Document>);

}

function AppWithProviders() {
  const data = useLoaderData<typeof loader>();
  const queryClient = useMemo(() => new QueryClient(), [data.accessToken]);

  return (
    <AuthenticityTokenProvider token={data.csrfToken}>
			<HoneypotProvider {...data.honeyProps}>
				<QueryClientProvider client={queryClient}>
					<App />
				</QueryClientProvider>
			</HoneypotProvider>
		</AuthenticityTokenProvider>);

}

export default withSentry(AppWithProviders);

export function ErrorBoundary() {
  // the nonce doesn't rely on the loader so we can access that
  const nonce = useNonce();

  // NOTE: you cannot use useLoaderData in an ErrorBoundary because the loader
  // likely failed to run so we have to do the best we can.
  // We could probably do better than this (it's possible the loader did run).
  // This would require a change in Remix.

  // Just make sure your root route never errors out and you'll always be able
  // to give the user a better UX.

  return (
    <Document nonce={nonce}>
			<GeneralErrorBoundary />
		</Document>);

}

const AppHeader = () => {
  const location = useLocation();
  return (
    <header className="container absolute left-0 right-0 top-0 py-6">
			<nav className="flex flex-wrap items-center justify-between gap-4 sm:flex-nowrap md:gap-8">
				<div className="container mx-auto max-w-7xl py-6 pl-0 pr-0 lg:pl-5 lg:pr-3">
					<div className="flex flex-wrap items-center justify-between gap-4 sm:flex-nowrap md:gap-8">
						<Link to="/" prefetch="intent">
							<img
                width={68}
                height={38}
                src="/img/landing-logo.svg"
                alt="Izzy" />

						</Link>

						<div className="flex flex-1 gap-2 max-xl:hidden">
							{desktopItems.map((item) =>
              item.to === '/services' ?
              <ServicesMenu key={item.to} /> :

              <Button asChild variant="ghost" key={item.to}>
										<NavLink
                  to={item.to}
                  className={cn(
                    'text-sm font-medium leading-normal text-white',
                    location.pathname.startsWith(item.to) &&
                    'bg-accent text-accent-foreground'
                  )}
                  prefetch="intent">

											{item.label ?? item.to}
										</NavLink>
									</Button>

              )}

							<div className="flex-1"></div>
							<DownloadApp>
								<Button variant="secondary" className="bg-white">
									Stáhnout aplikaci
								</Button>
							</DownloadApp>
							<div className="mx-1 w-[2px] self-stretch bg-white"></div>
							<Button variant="secondary" className="bg-white" asChild>
								<Link prefetch="intent" to="/dashboard/order-1">
									Objednat úklid
								</Link>
							</Button>
							<UserMenu />
						</div>

						<DropdownMenu>
							<DropdownMenuTrigger
                asChild
                className="hover:bg-white/20 xl:hidden">

								<Button variant="ghost">
									<svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none">

										<path
                      fill="#fff"
                      d="M7 18v-2h14v2H7Zm-4-5v-2h18v2H3Zm0-5V6h18v2H3Z" />

									</svg>
								</Button>
							</DropdownMenuTrigger>
							<MobileMenu />
						</DropdownMenu>
					</div>
				</div>
			</nav>
		</header>);

};