import Cookies from 'js-cookie'
import { useEffect, useState } from 'react'
import { Button } from './ui/button'

export const CookiesConsent = () => {
	const [isVisible, setIsVisible] = useState(false)

	useEffect(() => {
		setIsVisible(!Cookies.get('CookieConsent'))
	}, [])

	const handleAcceptCookies = () => {
		Cookies.set('CookieConsent', 'true', { expires: 150 })

		setIsVisible(false)
	}

	if (!isVisible) return

	return (
		<div className="fixed bottom-0 z-10 flex w-auto flex-col gap-8 bg-primary p-4 text-white max-xs:items-center max-xs:gap-4 max-xs:pb-24 max-xs:text-center sm:rounded-r-lg md:flex-row md:items-center">
			<div className="md:max-w-xs lg:max-w-max">
				Tyto webové stránky používají cookies ke zlepšení uživatelské
				zkušenosti.
			</div>
			<Button
				variant={'secondary'}
				className="w-48"
				onClick={handleAcceptCookies}
			>
				Souhlasím
			</Button>
		</div>
	)
}
